.search-container {
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #D0D9E9;
    height: 100vh;
    padding: 5rem 0;
  }
  
  h1 {
    font-size: 40px;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .search-card {
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #FFFFFF;
    padding: 2rem 3rem;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  }
  
  .search-field {
    margin-bottom: 2rem;
  }
  
  .search-field label {
    font-size: 20px;
    color: #555;
    margin-bottom: 1rem;
    display: block;
  }
  
  .search-field input[type="text"] {
    border: 1.5px solid #aaa;
    border-radius: 12px;
    font-size: 20px;
    padding: 1rem 1.2rem;
    outline: none;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
    transition: border-color 0.3s ease;
  }
  
  .search-field input[type="text"]:focus {
    border-color: #007BFF;
  }
  