/* JobListingsGrid.css */
.job-listings-container {
    padding: 2rem;
    /* margin-right: 240px; */


  }
  
  .heading {
    margin-bottom: 2rem;
  }
  
  .grid-item {
    margin-bottom: 2rem; /* Adjust this value to achieve the desired spacing */
  }

  /* JobListingsGrid.css */
.custom-drawer {
  width: 240px; /* Adjust this value as needed */
}

.job-listings-container {
  padding: 2rem;
  margin-right: 240px; /* This should match the width of the drawer */
}

/* JobListingsGrid.css */
.custom-sidebar {
  width: 240px; /* Adjust as needed */
  position: fixed; /* Fixed position to the right of the screen */
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto; /* Enables scrolling if the content is too long */
  background-color: #fff; /* Set the background color */
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for aesthetics */
  z-index: 1000; /* Ensure it's above other content */
  /* Add any other styling you want here */
}
.custom-sidebar .inner-element {
  max-width: 20%; /* Ensure it doesn't exceed the sidebar's width */
  /* Other styles... */
}
