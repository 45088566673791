
.search-bar {
  padding: 16px;
  width: 200px; 
}
.search-bar {
    background-color: #f5f5f5; /* Example background color */
    padding: 20px;
    border-radius: 8px;
}
  
  .search-bar button {
    font-weight: bold; /* Example font styling */
  }

  .search-bar .MuiOutlinedInput-root {
    border-radius: 4px;
    transition: border-color 0.2s;
  }
  
  .search-bar .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3f51b5; /* Primary color for focus */
  }

  
  .search-bar {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  