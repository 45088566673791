.job-details-container {
    padding: 2rem;
    min-height: 100vh;
  }
  
  .job-details-paper {
    padding: 2rem;
  }
  
  .back-button {
    margin-bottom: 1rem;
  }
  
  /* Styling for Typography */
  .MuiTypography-h3 {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .MuiTypography-subtitle1 {
    font-size: 1.1rem;
  }
  
  /* Styling for Divider */
  .MuiDivider-root {
    margin: 2rem 0;
  }
  